import { graphql } from 'gatsby'
import React, { useEffect, useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Hero from '../components/Hero'
import LocaleContext from '../components/LocaleContext'
import {bookADemoLinkSwitch} from '../components/lib/bookADemoLinkSwitch'
import ModalVideo from '../components/ModalVideo'
import RichText from '../components/RichText'
import BookDemoSection from '../components/BookDemoSection'

import './integration.scss'
import 'aos/dist/aos.css'


export default ({data}) => {
  const page = data.contentfulIntegrationPage
  const { 
    title, 
    localePrefix, 
    bannerTitle, 
    bannerDescription, 
    bannerLogo, 
    bodyText, 
    bodyImage, 
    testimonial, 
    integrationText, 
    integrationLink, 
    integrationGraphic, 
    freeTrialLink, 
    freeTrialText,
    bullet1Text,
    bullet1Icon,
    bullet2Text,
    bullet2Icon,
    bullet3Text,
    bullet3Icon,
    bullet4Text,
    bullet4Icon
  } = page
  const bannerText = bannerDescription && bannerDescription.childMarkdownRemark && bannerDescription.childMarkdownRemark.html
  const testimonialQuote = testimonial && testimonial.quote && testimonial.quote.childMarkdownRemark && testimonial.quote.childMarkdownRemark.html
  const video = testimonial && testimonial.video
  const imageVid = testimonial && testimonial.videoScreenshot && testimonial.videoScreenshot.file.url
  const testimonialIcon = testimonial && testimonial.logo && testimonial.logo.file.url

  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  const locale = useContext(LocaleContext)
  const demoLink = bookADemoLinkSwitch(locale.value)

  return (
    <Layout className="IntegrationLanding" title="" description="" locale={`en-${localePrefix.toUpperCase()}`}>
      <Hero variant="">
        <h1>{bannerTitle}</h1>
        <div dangerouslySetInnerHTML={{ __html: bannerText }} />
        <div className="brandLogoBox">
          {bannerLogo && bannerLogo.file.url &&
            <img src={bannerLogo.file.url} className="brandLogo" alt={title} />
          }
        </div>
      </Hero>

      <Section className="mt-md-6 mt-lg-0">
        <Container>
          <Row className="">
            <Col md={12} lg={12} className="mt-4">
              <Row>
                <Col md={6} className="d-flex" data-aos="fade-right" >
                  <div className="PartnerLanding__text w-100 pb-3 text-center py-md-5 text-md-left">
                    <div className="PartnerLanding__leftBox">
                      <RichText json={bodyText.json} />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="mb-3 mb-md-0 align-items-center d-flex" data-aos="fade-left" >
                  {bodyImage && bodyImage.file.url &&
                    <img src={bodyImage.file.url} className="Screenshot" alt={title} />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>
      
      {bullet1Text &&
      <Section className="mt-md-6 mt-lg-0 Bullets">
        <Container>
          <Row className="">
            {bullet1Text &&
            <Col xs={12} md={6} lg={6} className="d-flex align-items-start">
              {bullet1Icon && bullet1Icon.file.url &&
                <img src={bullet1Icon.file.url} className="Icon" alt={title} />
              }
              <div className="w-100 pb-3 text-left pr-md-5">
                <div className="">
                  <RichText json={bullet1Text.json} />
                </div>
              </div>
            </Col>
            }
            {bullet2Text &&
            <Col xs={12} md={6} lg={6} className="d-flex align-items-start">
              {bullet2Icon && bullet2Icon.file.url &&
                <img src={bullet2Icon.file.url} className="Icon" alt={title} />
              }
              <div className="w-100 pb-3 text-left pr-md-5">
                <div className="">
                  <RichText json={bullet2Text.json} />
                </div>
              </div>
            </Col>
            }
            {bullet3Text &&
            <Col xs={12} md={6} lg={6} className="d-flex align-items-start">
              {bullet3Icon && bullet3Icon.file.url &&
                <img src={bullet3Icon.file.url} className="Icon" alt={title} />
              }
              <div className="w-100 pb-3 text-left pr-md-5">
                <div className="">
                  <RichText json={bullet3Text.json} />
                </div>
              </div>
            </Col>
            }
            {bullet4Text &&
            <Col xs={12} md={6} lg={6} className="d-flex align-items-start">
              {bullet4Icon && bullet4Icon.file.url &&
                <img src={bullet4Icon.file.url} className="Icon" alt={title} />
              }
              <div className="w-100 pb-3 text-left pr-md-5">
                <div className="">
                  <RichText json={bullet4Text.json} />
                </div>
              </div>
            </Col>
            }
          </Row>
        </Container>
      </Section>}

      {testimonial && 
      <Section className="BannerSection-video py-md-2 position-relative">
        <Container>
          <Row className="my-2 my-md-5" data-aos="fade-up">
            {video &&
            <Col md={{ size: 4, offset: 1 }} lg={{ size: 4, offset: 1 }}>
              <div className="h-100 d-flex align-items-center flex-column justify-content-center text-center">
                <div>
                  {testimonialIcon && <img src={testimonialIcon} className="testimonial-icon mb-2" />}
                  <h3 className="my-2" dangerouslySetInnerHTML={{ __html: testimonialQuote }}></h3>
                  <p className="my-4 who">
                    {testimonial.whoName} {testimonial.jobTitle && " | " + testimonial.jobTitle}
                  </p>
                </div>
              </div>
            </Col>}

            {!video &&
            <Col md={{ size: 8, offset: 2 }} lg={{ size: 8, offset: 2 }}>
              <div className="h-100 d-flex align-items-center flex-column justify-content-center text-center">
                <div>
                  {testimonialIcon && <img src={testimonialIcon} className="testimonial-icon mb-2" />}
                  <h3 className="my-2" dangerouslySetInnerHTML={{ __html: testimonialQuote }}></h3>
                  <p className="my-4 who">
                    {testimonial.whoName} {testimonial.jobTitle && " | " + testimonial.jobTitle}
                  </p>
                  <br /><br />
                  <Button
                    color="outline"
                    href={integrationLink}
                    localePrefix={false}
                    className="mr-3"
                  >
                    Visit website
                  </Button>
                </div>
              </div>
            </Col>}

            {video &&
            <Col md={{ size: 5, offset: 1 }} lg={{ size: 5, offset: 1 }}>
                <ModalVideo {...video}>
                  {({ toggle }) => (
                    <div className="UntangleHrSection__video" onClick={toggle}>
                      <img src={imageVid} alt={'Video play'} className="testimonial-vid" />
                      <div className="UntangleHrSection__video-play-icon">
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="icon play-icon"
                        />
                      </div>
                    </div>
                  )}
                </ModalVideo>
            </Col>}
          </Row>
        </Container>
      </Section>}

      <BookDemoSection/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IntegrationPageByUrl($slug: String!) {
    contentfulIntegrationPage(slug: { eq: $slug }) {
      ...ContentfulIntegrationPageFragment
    }
  }
`